
import './App.css';
import { usePaystackPayment } from 'react-paystack';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
// b: pk_live_b83d1924325a3e7c00b7fccb4a898caa87627d4c
// w: pk_live_d3b73ac2625364b211baf7ac77f1659cf49f8981


function App() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // Get the current URL
  const [isLoading, setIsloading] = useState(false)
  const currentUrl = window.location.href;

  // Split the string at the '=' sign
  const parts = currentUrl.split('=');

  // Extract the text after the '=' sign (index 1)
  const extractedValue = parts[1];

  const config = {
    reference: (new Date()).getTime().toString() + "-" + extractedValue,
    email: new Date().getTime().toString() + "@fuser.com.ng",
    amount: 3000000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: 'pk_live_d3b73ac2625364b211baf7ac77f1659cf49f8981',
  };



  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.

    console.log(reference);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const initializePayment = usePaystackPayment(config);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(true)
      initializePayment(onSuccess, onClose)
    }, 2000)
  }, [initializePayment])

  return (

    <div className="App">
      <header className="App-header">
        <img src={"https://www.betfuse.org/6300063a98e7779e6bde13f5/6300091308414b8dca907f04_betfuse-logo(colored).png"} className="App-logo" alt="logo" />
        <p>
          Complete your subscription payment
        </p>
        {
          isLoading === true ?
            <a href="https://t.me/bet_fuse_bot" style={{ backgroundColor: "#fff", padding: 20, textDecoration: "none", borderRadius: 5, width: 250 }}>
              Return to Bot
            </a>
            :
            <code onClick={() => {
              initializePayment(onSuccess, onClose)
            }}>Please Wait...</code>
        }


      </header>
    </div>
  );
}

export default App;
